<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Productos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span
        style="
          border-bottom: 1px solid #002236;
          font-size: 1.5em;
          color: #002236;
          margin-bottom: none;
          font-weight: 500;
        "
        >REPORTE GENERAL DE PRODUCTOS</span
      >
    </div>
    <div class="col-12">
      <div class="col-12 p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <span><strong>GRUPO: </strong></span>
            <AutoComplete
              :dropdown="true"
              field="nombre"
              v-model="laboratorioSelected"
              :suggestions="laboratoriosListadoFiltrado"
              @complete="buscarLaboratorio($event)"
              placeholder="Buscar grupo"
            >
              <template #item="slotProps">
                <div>
                  {{ slotProps.item.nombre }}
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-5">
            <span><strong>NOMBRE PRODUCTO/SERVICIO: </strong></span>
            <InputText
              v-model="nombre_producto"
              placeholder="Buscar Producto/Servicio"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>TIPO CATALOGO: </strong></span>
            <Dropdown
              v-model="tipo_catalogo"
              :options="tipo_catalogos"
              optionLabel="nombre"
              optionValue="id"
              @change="BuscarFiltro"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="acciones"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Buscar Productos'"
              :loading="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="reporte_ventas"
          key="id"
          :value="productos"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Producto/Servicios..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <Button
                  v-if="'R_Productos DescargarPDF' in auth.user.permissions"
                  label="Imprimir PDF"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <Button
                  v-if="'R_Productos DescargarEXCEL' in auth.user.permissions"
                  label="Exportar Excel"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success mr-1"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportProductos"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="codigo_interno" header="CÓD_INT"> </Column>
          <Column field="descripcion" header="DESCRIPCIÓN/PRODUCTO">
            <template #body="{ data }">
              <div class="flex flex-column">
                <span class="text-sm font-bold">{{ data.descripcion }}</span>
              </div>
            </template>
          </Column>
          <Column field="nombre_laborario" header="GRUPO">
            {{ data.nombre_laborario }}
          </Column>

          <Column field="tipo_catalogo" header="T.CATÁLOGO">
            <template #body="{ data }">
              <span class="badge badge-success" v-if="data.tipo_catalogo == 1"
                >PRODUCTO</span
              >
              <span class="badge badge-info" v-else-if="data.tipo_catalogo == 2"
                >SERVICIO</span
              >
            </template>
          </Column>
          <Column
            field="precio_sugerido"
            header="P.VENTA"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </template>
          </Column>
          <Column
            field="precio_compra"
            header="C.CLÍNICA"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.precio_compra) }}
            </template>
          </Column>
          <Column
            field="comision_medico"
            header="C.MÉDICO"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.comision_medico) }}
            </template>
          </Column>
          <Column
            v-if="false"
            field="comision_vendedor"
            header="C.VENDEDOR"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.comision_vendedor) }}
            </template>
          </Column>
          <Column
            field="comision_solicitante"
            header="C.SOLICITANTE"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.comision_solicitante) }}
            </template>
          </Column>
          <Column
            v-if="false"
            field="comision_referido"
            header="C.REFERIDO"
            style="text-align: right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.comision_referido) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      generandoPDF: false,
      exportando: false,
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      tipo_catalogo: 0,
      tipo_catalogos: [
        { id: 0, nombre: "TODOS" },
        { id: 1, nombre: "PRODUCTO" },
        { id: 2, nombre: "SERVICIO" },
      ],
      nombre_producto: "",
      productos: [],
    };
  },
  productoService: null,
  laboratorioService: null,
  auth: null,
  created() {
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.auth = useAuth();
  },
  methods: {
    exportProductos() {
      if (this.productos.length > 0) {
        let datos = {
          fabrica: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          nombre_producto: this.nombre_producto,
          tipo_catalogo: this.tipo_catalogo,
        };
        this.productoService.exportarProductos(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación Exitosa",
            detail: "Se ha exportado correctamente los productos",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.productos.length > 0) {
        this.generandoPDF = true;
        let datos = {
          fabrica: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          nombre_producto: this.nombre_producto,
          tipo_catalogo: this.tipo_catalogo,
        };
        this.productoService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.productos = [];
      this.enviando = true;
      this.cargarReporteProductos();
    },
    cargarReporteProductos() {
      let datos = {
        fabrica: this.laboratorioSelected ? this.laboratorioSelected.id : null,
        nombre_producto: this.nombre_producto,
        tipo_catalogo: this.tipo_catalogo,
      };
      this.productoService
        .filtrarProductos(datos)
        .then((response) => {
          this.productos = response.productos;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      if (numero !== null && numero !== undefined) {
        return new Intl.NumberFormat("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numero);
      }
      return "0,00"; // Valor predeterminado si no hay número
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  border: 1px solid #000000;
}
.p-invalid {
  color: red;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
